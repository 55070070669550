import React from 'react';
import './App.css';
import Login from './components/Login';
import useToken from './components/useToken';
import Calendar from './components/Calendar';
import Weather from './components/Weather';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";


function App() {
  const { token, setToken } = useToken();

  if(!token) {
   return <Login setToken={setToken} />
 }

  return (
    <div className="wrapper">
      <span>Приветствие. {sessionStorage.getItem('token')}</span>
        <Weather/>      
        <Calendar/>
    </div>
  );
}

export default App;
