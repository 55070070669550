 //import './informer.min.css'
 //import 'https://www.gismeteo.ru/api/informer/getinformer/?hash=fT8C2Ctwf3661Y' 


// if (window.location.hostname !== "localhost") {
//     import('https://ost1.gismeteo.ru/assets/flat-ui/legacy/css/informer.min.css')
//     import('https://www.gismeteo.ru/api/informer/getinformer/?hash=fT8C2Ctwf3661Y')   
// }else(
//     import('./informer.min.css')
//     //await import('https://www.gismeteo.ru/api/informer/getinformer/?hash=fT8C2Ctwf3661Y')
// )

export default function () {  

    return(        
        
<div
  id="gsInformerID-6Dh0i2QQj2RuW0"
  className="gsInformer"
  style={{ width: 200, height: 162 }}
>
  <div className="gsIContent">
    <div id="cityLink">
      <a
        href="https://www.gismeteo.ru/weather-moscow-4368/"
        target="_blank"
        title="Погода в Москве"
        rel="noreferrer"
      >
        <img
          src="https://ost1.gismeteo.ru/assets/flat-ui/img/gisloader.svg"
          width={24}
          height={24}
          alt="Погода в Москве"
        />
      </a>
    </div>
    <div className="gsLinks">
      <table>
        <tbody>
          <tr>
            <td>
              <div className="leftCol">
                <a
                  href="https://www.gismeteo.ru/"
                  target="_blank"
                  title="Погода"
                  rel="noreferrer"
                >
                  <img
                    alt="Погода"
                    src="https://ost1.gismeteo.ru/assets/flat-ui/img/logo-mini2.png"
                    align="middle"
                    border={0}
                    width={11}
                    height={16}
                  />
                  <img
                    src="https://ost1.gismeteo.ru/assets/flat-ui/img/informer/gismeteo.svg"
                    border={0}
                    alt="logo"
                    align="middle"
                    style={{ left: 5, top: 1 }}
                  />
                </a>
              </div>
              <div className="rightCol">
                <a
                  href="https://www.gismeteo.ru/weather-moscow-4368/2-weeks/"
                  target="_blank"
                  title="Погода в Москве на 2 недели"
                  rel="noreferrer"
                >
                  <img
                    src="https://ost1.gismeteo.ru/assets/flat-ui/img/informer/forecast-2weeks.ru.svg"
                    border={0}
                    align="middle"
                    style={{ top: "auto" }}
                    alt="Погода в Москве на 2 недели"
                  />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>





    )
}
