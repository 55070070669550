import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' 
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import ruLocale from '@fullcalendar/core/locales/ru';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css';

import Modal from 'react-bootstrap/Modal';

export default function () {  


  //Обработка клика на событие
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBodyText, setModalBodyText] = useState("");
  const [events,setEvents] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let eventClickHandler = (arg) => {
     setModalTitle(arg.event.start.toLocaleDateString())
     setModalBodyText(`Работаешь с ${arg.event.extendedProps.companionPhrase}, меняешь ${arg.event.extendedProps.whomToReplace}`)
     handleShow()
  }

  async function getNGevents() {
    return fetch('https://sabjor.ru:8080', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },    
      body: JSON.stringify({"Method": "getCalend"})
    })
      .then(response => {
        return response.json()      
      })
      .then(data =>{        
        return data
      })
      .catch(e=>console.log(e));
   }
   
  //Собираем ивенты из NightGuards и парсим их для календаря
  
  const prepareEventsFromNG = async () =>{
    let events = []
    await getNGevents()
      .then( data => { 
        
        for (let key in data){       
            //let title = `${myCalendar[key].location} c ${myCalendar[key].companionPhrase.tv}`    
            let title = data[key].location                      //Название ивента (Где проходит смена)
            let date = new Date(data[key].date)                 //Сырая дата из файла
            let startDate = new Date(date)                            //Дата и время начала
            startDate.setHours(12)                                    //--Дата и время начала
            let endDate = new Date(date)                              //Дата и время окончания
            endDate.setDate(date.getDate() +1)                        //--Дата и время окончания
            endDate.setHours(12)                                      //--Дата и время окончания
            let companionPhrase = data[key].companionPhrase.tv  //С кем работаю
            let whomToReplace = data[key].whomToReplace.r      //Кого меняю    
        
            events.push({ title : title, start : startDate, end: endDate, classNames: 'ngevent', extendedProps: { companionPhrase: companionPhrase ,  whomToReplace: whomToReplace } })          
        }        
        setEvents(events)
        return events      
      }
      )
      return events
   }
   useEffect(() => {prepareEventsFromNG()}, [])   
   
  //Отрисовываем календарь и задаём его параметры
  return (
    <div>
    <FullCalendar
      locale= {ruLocale}
      plugins={[ dayGridPlugin, interactionPlugin, bootstrap5Plugin ]}
      headerToolbar={{
        left: "title",                  
        right: "prev next",
      }}
      events={events}
      initialView="dayGridMonth"
      themeSystem="bootstrap5"
      aspectRatio= "0.5"
      contentHeight = "auto"
      displayEventTime= {false}
      eventClick={eventClickHandler}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBodyText}</Modal.Body>
      </Modal>

      </div>
      );

}