import PropTypes from 'prop-types';
import './Login.css';

async function loginUser(credentials) {
 return fetch('https://sabjor.ru:8080', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json;charset=utf-8'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
   .catch(e=>console.log(e));
}


export default function Login({ setToken }) {

  let telegramData = window.Telegram.WebApp

  if (telegramData.initDataUnsafe.user !== undefined) {
    // let id = JSON.stringify(telegramData.initDataUnsafe.user.id)
    // let name = JSON.stringify(telegramData.initDataUnsafe.user.first_name)
    let id = telegramData.initDataUnsafe.user.id
    let name = telegramData.initDataUnsafe.user.first_name

    const handleSubmit = async e => {
      const token = await loginUser(   {
        "Method": "Login",
        "Params": {
          "id": id,
          "name": name
        }
      });
      if (token !== undefined) {
        let parsedInput = JSON.parse(token)
        let id = parsedInput.Params.id
        let name = parsedInput.Params.name
        let tokenToSet = {[id]: name}

        console.log(parsedInput)
        console.log(parsedInput.Params);
        //setToken({ token:token[0] });
        setToken( tokenToSet );       
      }else {
        console.log('Что-то не так с сервером');
      }
      //console.log(token[0]);

    }
    handleSubmit()

 

  }else {
    setToken({
      token:'123'
    })
    // return(
    //   <div className="login-wrapper">
    //     <span>Данная страница предназначена для работы с Telegram</span>
    //   </div>
    // )
  }
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
